<template>
  <div
    class="v-big-constructor-mod-single"
    :class="{ 'v-big-constructor-mod-single-disabled': disabled }"
    :inert="disabled"
  >
    <transition
      appear
      mode="out-in"
      name="bounce"
    >
      <div
        v-if="amount && amount > 0"
        class="v-big-constructor-mod-remove"
        :data-test-id="dataTestId && `${dataTestId}-mod-remove`"
        @click="onremove()"
      >
        <icon-general-trashcan />
      </div>
    </transition>
    <div
      class="v-big-constructor-mod-img-wrapper"
      :class="{ selected: amount && amount > 0 }"
      :data-test-id="dataTestId && `${dataTestId}-mod-single`"
      @click="onclick()"
    >
      <arora-nuxt-image
        v-if="mod.SmallImage"
        :alt="mod.Name"
        :image="mod.SmallImage"
        image-type="ProductSmall"
      />
    </div>
    <div
      v-if="mod.Price > 0"
      class="v-big-constructor-mod-price"
    >
      <common-currency :amount="mod.Price" />
    </div>
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="amount && amount > 0"
        class="v-big-constructor-mod-amount"
        :class="{ 'v-big-constructor-mod-amount-with-price': mod.Price > 0 }"
        @click="onclick()"
        v-html="`x${amount}`"
      />
    </transition>
  </div>
  <span v-html="sanitize(mod.Name)" />
</template>

<script setup lang="ts">
import type { ConstructorCategoryModifier } from '~types/menuStore'

import type { AutoTest, CanBeDisabled } from '@arora/common'

defineProps<
  AutoTest &
    CanBeDisabled & {
      mod: ConstructorCategoryModifier
      amount: number | undefined
      onclick: () => void
      onremove: () => void
    }
>()

const { sanitize } = useI18nSanitized()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-big-constructor-mod-single {
  position: relative;

  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: variables.$BorderRadius;
  margin-bottom: 20px;

  width: 100%;
  height: auto;

  .v-big-constructor-mod-remove {
    z-index: 3;
    padding: 2px;
    position: absolute;

    background-color: variables.$ErrorColor;
    color: variables.$ErrorForeColor;
    border-radius: 50%;
    border: 2px solid variables.$BodyElementsBackground;

    width: 35px;
    height: 35px;

    cursor: pointer;
    font-size: 16px;
    top: -7px;
    right: -10px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.v-big-constructor-mod-amount {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  z-index: 3;

  @include mixins.md {
    font-size: 35px;
  }
}

.v-big-constructor-mod-amount-with-price {
  top: 40%;
}

.v-big-constructor-mod-img-wrapper {
  background: none;
  cursor: pointer;
  position: relative;
  z-index: 1;

  border-radius: variables.$BorderRadius;

  width: 100%;
  height: 100%;

  @include mixins.trans;
  img {
    border-radius: calc(variables.$BorderRadius - 4px);
  }

  &.selected {
    background: #ccc;
    border: 2px solid variables.$PrimaryBackgroundColor;

    border-radius: variables.$BorderRadius 0 variables.$BorderRadius variables.$BorderRadius;

    img {
      transform: scale(0.95);
      filter: brightness(90%);
    }
  }

  &:hover {
    background: #bbb;
  }

  img {
    @include mixins.trans;

    &:hover {
      transform: scale(0.95);
      filter: brightness(85%);
    }
  }
}

.v-big-constructor-mod-price {
  background: variables.$PrimaryBackgroundColor;
  border: 3px solid variables.$BodyElementsBackground;
  color: variables.$PrimaryColor;

  border-radius: variables.$BorderRadius;
  display: inline-flex;
  padding: 5px 10px;
  transform: translateY(20px);
  position: absolute;
  bottom: 0;
  z-index: 2;

  @include mixins.md {
    margin: -10px auto 5px;
  }
}

.v-big-constructor-mod-single-disabled {
  pointer-events: none;

  img {
    @include mixins.trans;
    filter: saturate(0%);
  }

  .v-big-constructor-mod-price {
    @include mixins.trans;
    filter: saturate(0%);
  }
}
</style>
